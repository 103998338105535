/* eslint-disable no-unused-vars */

function processScrollVisibilityPanel(cScrollContainer = '.blockContent', cPanel = '.topBarFront .blockLogos') {
  const scrollContainer = document.querySelector(cScrollContainer);
  const headerBar = document.querySelector(cPanel);
  if (!scrollContainer || !headerBar) return false;
  const headerBarOffset = headerBar.getBoundingClientRect().height;
  scrollContainer.addEventListener('scroll', () => {
    const docTop = scrollContainer.scrollTop;
    const diff = headerBarOffset - docTop;
    if (diff >= 0) {
      headerBar.classList.remove('hide');
    } else {
      headerBar.classList.add('hide');
    }
  });
  return true;
}
