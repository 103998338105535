/* eslint-disable no-unused-vars, no-cond-assign */

class MonadicList {
  constructor(xs) {
    this.xs = xs;
  }
  map(f) {
    const { xs } = this;
    return new MonadicList({
      * [Symbol.iterator]() {
        let i = 0;
        for (const x of xs) {
          yield f(x, i += 1);
        }
      },
    });
  }
  zip(other) {
    const it1 = this.xs[Symbol.iterator]();
    const it2 = other.xs[Symbol.iterator]();
    return new MonadicList({
      * [Symbol.iterator]() {
        let step1;
        let step2;
        while (!(step1 = it1.next()).done && !(step2 = it2.next()).done) {
          yield [step1.value, step2.value];
        }
      },
    });
  }
  forEach(f) {
    for (const i of this.xs) {
      f(i);
    }
  }
}
