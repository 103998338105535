/* eslint-disable no-unused-vars, camelcase */

/*
 * This was taken from stackoverflow:
 * http://stackoverflow.com/questions/7501761/div-scrollbar-width
 */
function dsfe_getScrollbarWidth() {
  const div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div></div>');
  $('body').append(div);
  const w1 = $('div', div).innerWidth();
  div.css('overflow-y', 'auto');
  const w2 = $('div', div).innerWidth();
  $(div).remove();
  return (w1 - w2);
}

/*
 * Breaks the given text to parts based on matches.
 * It returns an array of text parts by specifying whether each part should be highlighted or not.
 */
function dsfe_highlightTextParse(text, matches) {
  const result = [];

  if (matches.length === 0) {
    result.push({
      text,
      highlight: false,
    });
  } else if (matches[0][0] > 0) {
    result.push({
      text: text.slice(0, matches[0][0]),
      highlight: false,
    });
  }
  matches.forEach((match, i) => {
    const startIndex = match[0];
    const endIndex = match[1];
    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true,
    });
    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false,
        });
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false,
      });
    }
  });

  return result;
}
