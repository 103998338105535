/* eslint-disable no-unused-vars, no-param-reassign, valid-typeof */
class NumberMask {
  constructor(minusRegExp = '/-/', nonDigitsRegExp = /\s+/g, numberType = 'number', digitRegExp = /\S/, caretTrap = '[]') {
    this.dollarSign = '$';
    this.emptyString = '';
    this.comma = ',';
    this.period = '.';
    this.minus = '-';
    this.minusRegExp = minusRegExp;
    this.nonDigitsRegExp = nonDigitsRegExp;
    this.number = numberType;
    this.digitRegExp = digitRegExp;
    this.caretTrap = caretTrap;
  }

  createNumberMask({
    prefix = this.dollarSign,
    suffix = this.emptyString,
    includeThousandsSeparator = true,
    thousandsSeparatorSymbol = this.comma,
    allowDecimal = true,
    decimalSymbol = this.period,
    decimalLimit = 2,
    requireDecimal = false,
    allowNegative = false,
    allowLeadingZeroes = true,
    integerLimit = null,
  } = {}) {
    const prefixLength = prefix ? prefix.length : 0;
    const suffixLength = suffix ? suffix.length : 0;
    const thousandsSeparatorSymbolLength = thousandsSeparatorSymbol
      ? thousandsSeparatorSymbol.length
      : 0;

    function numberMask(rawValue = this.emptyString) {
      const rawValueLength = rawValue.length;
      if (rawValue === this.emptyString || (rawValue[0] === prefix[0] && rawValueLength === 1)
      ) {
        return prefix.split(this.emptyString)
          .concat([this.digitRegExp])
          .concat(suffix.split(this.emptyString));
      }

      const indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol);
      const hasDecimal = indexOfLastDecimal !== -1;
      const isNegative = (rawValue[0] === this.minus) && allowNegative;

      let integer;
      let fraction;
      let mask;

      // remove the suffix
      if (rawValue.slice(suffixLength * -1) === suffix) {
        rawValue = rawValue.slice(0, suffixLength * -1);
      }

      if (hasDecimal && (allowDecimal || requireDecimal)) {
        integer = rawValue.slice(rawValue.slice(0, prefixLength) === prefix ?
          prefixLength : 0, indexOfLastDecimal);
        fraction = rawValue.slice(indexOfLastDecimal + 1, rawValueLength);
        fraction = this.convertToMask(fraction.replace(this.nonDigitsRegExp, this.emptyString));
      } else if (rawValue.slice(0, prefixLength) === prefix) {
        integer = rawValue.slice(prefixLength);
      } else {
        integer = rawValue;
      }

      if (integerLimit && typeof integerLimit === this.number) {
        const thousandsSeparatorRegex = thousandsSeparatorSymbol === '.' ? '[.]' : `${thousandsSeparatorSymbol}`;
        const numberOfThousandSeparators = (integer.match(new RegExp(thousandsSeparatorRegex, 'g')) || []).length;
        integer = integer.slice(0, integerLimit
          + (numberOfThousandSeparators * thousandsSeparatorSymbolLength));
      }
      integer = integer.replace(this.nonDigitsRegExp, this.emptyString);
      if (!allowLeadingZeroes) {
        integer = String(Number(integer));
      }

      integer = (includeThousandsSeparator)
        ? addThousandsSeparator(integer, thousandsSeparatorSymbol)
        : integer;

      mask = this.convertToMask(integer);

      if ((hasDecimal && allowDecimal) || requireDecimal === true) {
        if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
          mask.push(this.caretTrap);
        }

        mask.push(decimalSymbol, this.caretTrap);

        if (fraction) {
          if (typeof decimalLimit === this.number) {
            fraction = fraction.slice(0, decimalLimit);
          }

          mask = mask.concat(fraction);
        }

        if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
          mask.push(this.digitRegExp);
        }
      }

      if (prefixLength > 0) {
        mask = prefix.split(this.emptyString).concat(mask);
      }

      if (isNegative) {
        /* If user is entering a negative number,
           add a mask placeholder spot to attract the caret to it. */
        if (mask.length === prefixLength) {
          mask.push(this.digitRegExp);
        }
        mask = [this.minusRegExp].concat(mask);
      }

      if (suffix.length > 0) {
        mask = mask.concat(suffix.split(this.emptyString));
      }
      return mask;
    }

    numberMask.instanceOf = 'createNumberMask';

    return numberMask.bind(this);
  }

  convertToMask(strNumber) {
    return strNumber
      .split(this.emptyString)
      .map(char => (this.digitRegExp.test(char) ? this.digitRegExp : char));
  }
}

function createAutoCorrectedDatePipe(
  dateFormat = 'mm dd yyyy',
  dateMax = {
    day: 31, month: 12, year: 9999, hour: 23, minute: 59,
  },
  dateMin = {
    day: 1, month: 1, year: 1000, hour: 0, minute: 0,
  },
) {
  return function (conformedValue) {
    const indexesOfPipedChars = [];
    const dateFormatArray = dateFormat.split(/[^dmyhi]+/);
    const maxValue = {
      dd: dateMax.day, mm: dateMax.month, yyyy: dateMax.year, hh: dateMax.hour, ii: dateMax.minute,
    };
    const minValue = {
      dd: dateMin.day, mm: dateMin.month, yyyy: dateMin.year, hh: dateMin.hour, ii: dateMin.minute,
    };
    const conformedValueArr = conformedValue.split('');
    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format);
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);
      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position];
        conformedValueArr[position] = 0;
        indexesOfPipedChars.push(position);
      }
      if (conformedValueArr[position] === '1' && conformedValueArr[position + 1] === '.') {
        conformedValueArr[position + 1] = conformedValueArr[position];
        conformedValueArr[position] = 0;
      }
    });

    // Check for invalid date
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format);
      const { length } = format;
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '');
      const value = parseInt(textValue, 10);
      return value > maxValue[format] || (textValue.length === length && value < minValue[format]);
    });
    if (isInvalid) {
      return false;
    }
    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars,
    };
  };
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n, thousandsSeparatorSymbol) {
  return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
}
