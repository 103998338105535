/**
 * This function creates list of switcher. Switcher can show or hide block,
 * which are specified of class "switching-blocks".
 * Each switcher has data attribute "data-active" -
 * name of block id bindig with actions (hide/show).
 * @param {String} cSwitcher - css class for block with radios (default value .list-switcher)
 * @param {String} cBlocks - css class for determine blocks (default value .switching-blocks)
 * @returns {Boolean}
 */

/* eslint-disable no-unused-vars */

function createVisibilitySwitcher(cSwitcher = '.list-switcher', cBlocks = '.switching-blocks') {
  const pointControls = document.querySelector(cSwitcher);
  const blocks = document.querySelector(cBlocks);
  const activeElem = document.querySelector(`${cSwitcher} input[checked]`);
  if (!pointControls || !blocks || !activeElem) return false;
  switchBlock(blocks, activeElem, cBlocks);

  // set action
  pointControls.addEventListener('change', (e) => {
    e.stopPropagation();
    const elem = e.target;
    switchBlock(blocks, elem, cBlocks);
  });
  return true;
}

function switchBlock(blocks, dot, cBlocks) {
  const list = blocks.querySelectorAll(`${cBlocks} > div`);
  for (let i = 0; i < list.length; i += 1) {
    list.item(i).classList.add('hide');
  }
  document.getElementById(dot.getAttribute('data-active')).classList.remove('hide');
}
