/* eslint-disable no-param-reassign */
(function ($, window) {
  const pluginName = 'floatingTableHeaders';
  const defaults = {
    fixedOffset: 0,
    container: null,
    scrollContainer: null,
    tableWrapper: null,
    hasClonedHeader: true,
    floatingClass: null,
    scrollTable: false,
    visibleClass: 'tfc-is-visible',
    cloneTarget: null,
  };

  function Plugin(el, options) {
    // To avoid scope issues, use 'base' instead of 'this'
    // to reference this class from internal events and functions.
    const base = this;
    base.options = { ...defaults, ...options };
    base.el = el;
    base.$el = $(el);
    const {
      floatingHeaderClonedTableWrapper,
      floatingHeaderClonedHeader,
      floatingHeaderFixedOffset,
      floatingHeaderClonedHeaderTarget,
      floatingHeaderContainer,
      floatingHeaderScrollContainer,
      floatingTableClass,
      floatingScrollTable,
      floatingHeaderVisibleClass,
    } = base.$el.data();
    base.$window = $(window);
    base.$clone = null;
    base.$clonedHeader = null;
    base.$originalHeader = null;
    base.cloneBorderTopWidth = 0;
    base.horizontalScrollContainer = base.options.tableWrapper || null;
    base.hasClonedHeader = floatingHeaderClonedHeader || base.options.hasClonedHeader;
    base.fixedOffset = floatingHeaderFixedOffset || base.options.fixedOffset;
    base.container = base.options.container || 'window';
    base.$container = base.options.container !== null ? $(base.options.container) : base.$window;
    base.cloneTarget = floatingHeaderClonedHeaderTarget || base.options.cloneTarget;
    base.$cloneTarget = $(base.cloneTarget);

    if (floatingHeaderContainer && base.options.container === null) {
      base.container = floatingHeaderContainer;
      base.$container = $(floatingHeaderContainer);
    }

    base.$scrollContainer = floatingHeaderClonedTableWrapper && base.options.tableWrapper
      ? floatingHeaderClonedTableWrapper
      : base.horizontalScrollContainer;

    base.$scrollContainer = base.options.scrollContainer !== null
      ? $(base.options.scrollContainer)
      : base.$container;

    base.$scrollContainer = base.options.scrollContainer || base.$container;

    base.$scrollContainer = floatingHeaderScrollContainer && base.options.scrollContainer
      ? floatingHeaderScrollContainer
      : base.$scrollContainer;

    base.floatingClass = floatingTableClass || base.options.floatingClass;
    base.scrollTable = floatingScrollTable || base.options.scrollTable;
    base.visibleClass = floatingHeaderVisibleClass || base.options.visibleClass;
    base.cloneOpacity = base.scrollTable ? 1 : 0;
    base.$horizontalScrollContainer = base.$el.closest(base.horizontalScrollContainer);

    if (base.scrollTable) {
      base.$el.addClass('tfc-tableFloatingScrollContainer');
    }

    /* Need to use element to get offset if container is window.
     * Otherwise use container's offset for calculations.
     */
    base.getContainerOffset = () => {
      const containerOffset = base.$scrollContainer.offset();
      const elementOffset = base.$el.offset();
      return containerOffset
        ? { top: (containerOffset.top), left: 0 }
        : { top: 0, left: elementOffset.left };
    };
    /* We need to know how much to scroll to activate and deactivate the
       * sticky header. I originally tried to calculate this using .position
       * on the child element. This works fine so long as the parent element
       * has its position set to something other than "static". Please
       * see this stackoverflow thread for why:
       *  http://stackoverflow.com/questions/2842432/jquery-position-isnt-returning-offset-relative-to-parent
       *
       * So to get this to work everywhere, I grab the difference from
       * the top of the child element and the top of the parent element
       * when the page loads. This should tell us how much we need to
       * scroll to activate the sticky header.
       *
       * Also - the offset function does not seem to take any table
       * captions into consideration. So we check for a table caption
       * and add this in to the amount we need to scroll for an
       * activation.
       */
    let startTopOffset = (base.$el.offset().top + base.$scrollContainer.scrollTop())
      - base.getContainerOffset().top;
    const caption = base.$el.find('caption');
    if (caption.length) {
      startTopOffset += caption.height();
    }
    base.scrollAmountToActivate = startTopOffset;
    base.scrollAmountToDeactivate = base.scrollAmountToActivate + base.$el.height();

    // Keep track of state
    base.isCloneVisible = false;
    base.leftOffset = null;
    base.topOffset = null;

    base.calcNewHeaderPosition = () => {
      const containerOffset = base.getContainerOffset();
      const scrollLeft = base.$horizontalScrollContainer.scrollLeft() + base.$window.scrollLeft();
      const newLeft = containerOffset.left - scrollLeft;
      if (!base.scrollTable) {
        base.$clone.addClass(base.visibleClass);
      }
      base.$clonedHeader.css({
        'margin-top': 0,
        transform: `translateX(${newLeft}px)`,
      });
    };

    base.windowToggleHeaders = () => {
      base.toggleHeaders();
      if (base.isCloneVisible) {
        base.calcNewHeaderPosition();
      }
    };

    base.init = () => {
      base.$el.each(function () {
        const $this = $(this);
        // remove padding on <table> to fix issue #7
        $this.css('padding', 0);
        base.$el.on('sth-destroy', () => {
          base.destroy();
        });
        base.$el.on('sth-update', () => {
          base.recalculateStartTopOffset();
        });
        base.$el.on('sth-resize', () => {
          base.toggleHeaders();
          base.updateWidth();
        });

        base.$originalHeader = $('thead:first', this);
        if (base.hasClonedHeader) {
          const tableClone = $this.clone();
          tableClone.find('table').children().not('thead').remove();

          base.$clone = $('<div>', { class: 'tfc-tableFloatingContainer' }).append($('<div>', { class: 'tfc-tableFloatingWrapper' }).append(tableClone));
          base.$clone.addClass(base.floatingClass);
          if (base.$cloneTarget.length) {
            base.$cloneTarget.append(base.$clone);
          } else {
            base.$container.before(base.$clone);
          }
        } else {
          const templateTableHeadSelector = $this.closest('[data-floating-table]').attr('data-floating-table');
          base.$clone = base.$container.siblings().find(`[data-template-table="${templateTableHeadSelector}"]`);
        }
        let tableLeftOffset = base.$horizontalScrollContainer.offset().left
          - base.$scrollContainer.offset().left;
        tableLeftOffset = Math.abs(tableLeftOffset);
        const horizontalScrollContainer = base.$horizontalScrollContainer.css('width');
        const resultWidth = tableLeftOffset + parseFloat(horizontalScrollContainer);
        base.$clone.css({
          width: resultWidth,
          'padding-left': tableLeftOffset,
          'padding-right': 0,
        });
        if (!base.scrollTable) {
          base.$clone.removeClass(base.visibleClass);
        }
        const $clonedInnerWrapper = base.$clone.find('[data-floating-table]');
        const $clonedTable = base.$clone.find('table');
        $clonedInnerWrapper.addClass('tfc-tableFloatingInnerWrapper');
        $clonedTable.addClass('tfc-tableFloatingTable');

        if (base.scrollTable) {
          base.$clone.addClass('tfc-clonedHeaderScrollContainer');
        }

        base.$clonedHeader = base.$clone.find('thead');
        base.$clonedHeader.addClass('tableFloatingHeader');
        base.$originalHeader.addClass('tableFloatingHeaderOriginal');
        base.recalculateStartTopOffset();
      });

      base.updateWidth();
      base.toggleHeaders();

      base.$horizontalScrollContainer.scroll(base.toggleHeaders);
      base.$scrollContainer.scroll(base.toggleHeaders);
      base.$window.scroll(base.windowToggleHeaders);
    };

    base.recalculateStartTopOffset = function () {
      startTopOffset = (base.$el.offset().top + base.$scrollContainer.scrollTop())
        - base.getContainerOffset().top;
      base.scrollAmountToActivate = startTopOffset;
      base.scrollAmountToDeactivate = (base.scrollAmountToActivate + base.$el.height())
        - base.$originalHeader.outerHeight(true) - this.$el.find('tr').last().height();
    };

    base.toggleHeaders = () => {
      base.$el.each(() => {
        if (base.$scrollContainer.scrollTop() < base.$originalHeader.height()) {
          base.recalculateStartTopOffset();
        }
        const floatingWrapper = base.$clone.children();
        base.cloneBorderTopWidth = (parseInt(floatingWrapper.css('borderTopWidth'), 10));
        const newTopOffset = Number.isNaN(parseFloat(base.fixedOffset))
          ? base.fixedOffset.height()
          : base.fixedOffset;
        const containerOffset = base.getContainerOffset();
        const elementOffset = base.$el.offset();
        const scrollTop = base.$scrollContainer.scrollTop() + newTopOffset;
        const windowScrollTop = base.$window.scrollTop();
        const scrollLeft = base.$horizontalScrollContainer.scrollLeft();
        const scrolledEnoughToActivate = base.scrollTable
          ? (scrollTop > 0)
          : (scrollTop > (base.scrollAmountToActivate - base.cloneBorderTopWidth))
          || (windowScrollTop > elementOffset.top);

        if (scrolledEnoughToActivate
          && (scrollTop < (base.scrollAmountToDeactivate - base.cloneBorderTopWidth))) {
          const newLeft = containerOffset.left - scrollLeft;
          if (base.isCloneVisible
            && (newLeft === base.leftOffset)
            && (newTopOffset === base.topOffset)) {
            return;
          }
          base.calcNewHeaderPosition();
          base.updateWidth();

          base.$originalHeader.css('visibility', 'hidden');
          base.isCloneVisible = true;
          base.leftOffset = newLeft;
          base.topOffset = newTopOffset;
        } else if (base.isCloneVisible) {
          if (!base.scrollTable) {
            base.$clone.removeClass(base.visibleClass);
          }
          base.$originalHeader.css('visibility', 'visible');
          base.isCloneVisible = false;
        }
      });
    };

    base.updateWidth = () => {
      // Copy cell widths and classes from original header
      $('th', base.$clonedHeader).each(function (index) {
        const $this = $(this);
        const $origCell = $('th', base.$originalHeader).eq(index);
        this.className = $origCell.attr('class') || '';
        $this.css('width', $origCell.css('width'));
      });
      // Copy row width from whole table
      base.$clone.find('table').css('width', base.$originalHeader.css('width'));

      const tableLeftOffset = Math.abs(base.$el.offset().left
        - base.$scrollContainer.offset().left);
      const horizontalScrollContainer = base.$el.css('width');
      const resultWidth = tableLeftOffset + parseFloat(horizontalScrollContainer);
      base.$clone.css('width', resultWidth);
      base.$clone.css('padding-left', tableLeftOffset);
    };

    base.destroy = () => {
      $(base.$clone).remove();
      base.$originalHeader.css('visibility', 'visible');
      base.$horizontalScrollContainer.off('scroll', base.toggleHeaders);
      base.$scrollContainer.off('scroll', base.toggleHeaders);
      base.$window.off('scroll', base.windowToggleHeaders);
    };

    // Run initializer
    base.init();
  }
  // jQuery plugin wrapper around the constructor,
  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (options.method) {
        $(this).trigger(`sth-${options.method}`);
      } else {
        $.data(this, `plugin_${pluginName}`, new Plugin(this, options));
      }
    });
  };
}(jQuery, window));
