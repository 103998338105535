/* eslint-disable no-new */

// Required jQuery

class FloatingScrollbar {
  constructor(containerSelector, scrollContainers, bottomFlexContainer, scrollbarSize) {
    this.$container = $(containerSelector);
    this.container = this.$container.get(0);
    this.scrollContainers = scrollContainers;
    const $scrollBody = this.$container.closest('.fl-scrolls-body');
    this.$scrollBody = $scrollBody.length ? $scrollBody : null;
    this.bottomFlexContainer = bottomFlexContainer ? $(bottomFlexContainer) : null;
    this.visible = true;
    this.scrollbarSize = scrollbarSize || window.dsfe_getScrollbarWidth();
    this.scrollbar = this.initScroll();
    this.syncScrollbar(this.container);
    for (const sContainer of this.scrollContainers) {
      const $container = $(sContainer);
      this.addEventHandlers($container);
      // recalculate floating scrolls and hide those of them whose containers are out of sight
      this.update($container);
    }
  }

  updateContainersSize() {
    for (const container of this.scrollContainers) {
      this.checkVisibility($(container));
    }
    this.update();
  }

  initScroll() {
    const $flScrollbar = $(`<div data-fl-scroll-holder class="fl-scrolls ${this.bottomFlexContainer ? '' : 'fl-fixed'}"></div>`);
    $('<div></div>').appendTo($flScrollbar).css({ width: ` ${this.container.scrollWidth}px` });
    if (this.bottomFlexContainer) {
      const scrollbar = $flScrollbar.prependTo(this.bottomFlexContainer);
      scrollbar.css('top', `-${this.scrollbarSize}px`);
      return scrollbar;
    }
    return $flScrollbar.appendTo(this.$container);
  }

  addEventHandlers($scrollContainer) {
    this.currentEvent = null;
    this.eventHandlers = [
      {
        $el: $scrollContainer || this.$scrollBody || $(window),
        handlers: {
          // Don't use `$.proxy()` since it makes impossible event
          // unbinding individually per instance
          // (see the warning at http://api.jquery.com/unbind/)
          scroll: () => { this.checkVisibility($scrollContainer); },
          resize: () => { this.update(); },
        },
      },
      {
        $el: this.scrollbar,
        handlers: {
          scroll: (e) => {
            if (this.visible && (this.currentEvent === null || this.currentEvent === 'sbarScroll')) {
              this.syncContainer(e.target);
              FloatingScrollbar.setCurrentEvent(e.target, 'sbarScroll');
            }
          },
        },
      },
      {
        $el: $(this.$container),
        handlers: {
          scroll: (e) => {
            if (this.currentEvent === null || this.currentEvent === 'contScroll') {
              this.syncScrollbar(e.target);
              FloatingScrollbar.setCurrentEvent(e.target, 'contScroll');
            }
          },
          /* Check event namespace to ensure
             that this is not an extraneous event  in a bubbling phase
           */
          'update.fscroll': (e) => {
            if (e.namespace === 'fscroll') {
              this.update();
            }
          },
          'destroy.fscroll': (e) => {
            if (e.namespace === 'fscroll') {
              this.destroy();
            }
          },
        },
      },
    ];
    const handlers = this.eventHandlers;
    for (let i = 0, len = handlers.length; i < len; i += 1) {
      handlers[i].$el.on(handlers[i].handlers);
    }
  }

  checkVisibility($scrollContainer) {
    let mustHide = (this.scrollbar.scrollWidth <= this.scrollbar.offsetWidth);
    let contRect;
    let maxVisibleY;
    if (!mustHide) {
      contRect = this.container.getBoundingClientRect();
      if ($scrollContainer) {
        maxVisibleY = $scrollContainer
          ? $scrollContainer[0].getBoundingClientRect().bottom
          : $($scrollContainer).height();
      } else {
        maxVisibleY = this.$scrollBody
          ? this.$scrollBody[0].getBoundingClientRect().bottom
          : window.innerHeight || document.documentElement.clientHeight;
      }
      mustHide = ((contRect.bottom <= maxVisibleY) || (contRect.top > maxVisibleY));
    }
    if (this.visible === mustHide) {
      this.visible = !mustHide;
      /* we cannot simply hide a floating scroll bar since its
         scrollLeft property will not update in that case */
      $('[data-fl-scroll-holder]').toggleClass('fl-scrolls-hidden');
    }
  }

  syncContainer(sender) {
    this.container.scrollLeft = sender.scrollLeft;
  }

  syncScrollbar(sender) {
    this.scrollbar.each((i, bar) => {
      const scrollbar = bar;
      scrollbar.scrollLeft = sender.scrollLeft;
    });
  }

  // Recalculate scroll width and container boundaries
  update(scrollContainer) {
    const $container = $(this.$container);
    const container = $container.get(0);
    const pos = container.getBoundingClientRect();
    this.scrollbar.width($container.outerWidth());
    if (!this.$scrollBody && !this.bottomFlexContainer) {
      this.scrollbar.css('left', `${pos.left}px`);
    }
    $('div', this.scrollbar).width(container.scrollWidth);
    this.checkVisibility(scrollContainer);
  }

  // Remove a scrollbar and all related event handlers
  destroy() {
    const handlers = this.eventHandlers;
    for (let i = 0, len = handlers.length; i < len; i += 1) {
      handlers[i].$el.off(handlers[i].handlers);
    }
    this.scrollbar.remove();
  }

  static setCurrentEvent(sender, currentEvent) {
    const cSender = sender;
    cSender.currentEvent = currentEvent;
    clearTimeout(sender.syncThrottle);
    cSender.syncThrottle = setTimeout(() => {
      cSender.currentEvent = null;
    }, 300);
  }
}

$.fn.floatingScrollbar = function ({
  method,
  scrollContainers,
  bottomFlexContainer,
  scrollbarSize,
}) {
  if (method === 'init' || !method) {
    this.each((index, el) => {
      new FloatingScrollbar(el, scrollContainers, bottomFlexContainer, scrollbarSize);
    });
  } else if (Object.prototype.hasOwnProperty.call(FloatingScrollbar.prototype, method)) {
    this.trigger(`${method}.fscroll`);
  }
  return this;
};
